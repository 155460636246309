import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";

import { t, Trans } from "@lingui/macro";
import { Col, Row, Space, Typography } from "antd";
import { PageProps } from "layout";
import styled from "styled-components";

import StyledLink from "components/link";

import { useApplicationContext } from "contexts/app";

import useSetPageTitle from "hooks/set-page-title";

const AuthenticationForm = dynamic(() => import("components/authentication"), {
  ssr: false,
});
export const itunesLink = "https://itunes.apple.com/ee/app/navirec-com/id1114452674?mt=8";
export const playStoreLink = "https://play.google.com/store/apps/details?id=eu.gsmauto.navirec";

const ResetPasswordContainer = styled(Row)`
  margin-bottom: 24px;
`;

const Login: PageProps = () => {
  const { environment } = useApplicationContext();

  useSetPageTitle("Login");

  return (
    <Row justify="center" align={"middle"}>
      <Col span={24}>
        <Row align={"middle"} justify={"center"}>
          <Typography.Title level={3} data-testid="login-title">
            <Trans>Login</Trans>
          </Typography.Title>
        </Row>
      </Col>

      <Col lg={5} md={12} sm={12} xs={20}>
        <AuthenticationForm authMethods={environment?.authentication_methods || []} data-testid="authentication-form" />
        <ResetPasswordContainer align={"middle"} justify={"center"}>
          <Typography.Text strong>
            <StyledLink href="/password/forgot">
              <Trans>Forgot your password?</Trans>
            </StyledLink>
          </Typography.Text>
        </ResetPasswordContainer>
      </Col>
      <Col span={24}>
        <Row align={"middle"} justify={"center"}>
          <Space>
            <Link href={itunesLink} target="_blank" rel="noopener noreferrer" data-testid="apple-store-link">
              <Image src="/icons/appstore.svg" alt={t`Apple App Store`} width="130" height="40" />
            </Link>
            <Link href={playStoreLink} target="_blank" rel="noopener noreferrer" data-testid="play-store-link">
              <Image src="/icons/playstore.svg" alt={t`Google Play Store`} width="130" height="40" />
            </Link>
          </Space>
        </Row>
      </Col>
    </Row>
  );
};

export default Login;
Login.Layout = "Public";
