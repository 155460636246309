import Link from "next/link";

import styled from "styled-components";

const StyledLink = styled(Link)`
  color: #2f2f2f !important;
  :hover {
    text-decoration: underline !important;
  }
`;

export default StyledLink;
